.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix:before {
  content: '';
  line-height: 0;
  display: table;
}
.clearfix:after {
  content: '';
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  overflow: hidden;
}
.clearfix {
  *zoom: 1;
}
.text-c {
  text-align: center;
}
.text-l {
  text-align: left;
}
.text-r {
  text-align: right;
}
.break-word {
  word-wrap: break-word;
  word-break: break-word;
}
.white-nowrap {
  white-space: nowrap;
}
.ant-spin-container > .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.handle-btn {
  margin: 3px;
}
.ant-table-wrapper.middle-table {
  td {
    vertical-align: middle;
  }
}

.manager-table-warp {
  margin: 0 10px;
  .form-manager {
    border-bottom: 0;
    font-size: 13px;
    color: #333;
    padding: 8px 15px;
  }
  .form-table {
    font-size: 13px;
  }
}

.search-form-wrap {
  padding: 10px 20px;
}
.red-light {
  color: #E51C23;
}
.align-bottom-thead th {
  vertical-align: bottom;
}
.ant-table-fixed{
  border-collapse:collapse !important;
}
.headerRow table{
  border-collapse:collapse !important;
}
.headerRow th{
  vertical-align: bottom;
  border:1px solid black;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  border-collapse:collapse !important;
  color:black !important;
  text-align: center;
  font-size: 12px !important;
}

.handle-link {
  color: #1890ff;
  cursor: pointer;
  &:hover {
      color: #40a9ff;
  }
}
.left-paginTable {
  .ant-table-pagination.ant-pagination {
    float: left;
  }
}

.ant-btn + .ant-btn {
  margin-left: 15px;
}
.ant-btn-link + .ant-btn-link {
  margin-left: 0;
}
.tooltip-color {
  .ant-tooltip-inner {
    background-color: #1890ff;
  }
  .ant-tooltip-arrow::before {
    background-color: #1890ff;
  }
}
.tooltip-width {
  .ant-tooltip-content {
    width: 430px;
  }
}
.orangeTip-color {
  .ant-tooltip-inner {
    background-color: #F08727;
  }
  .ant-tooltip-arrow::before {
    background-color: #F08727;
  }
}
.com-flex {
  width: 100%;
  display: flex;
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.common-box {
  .ant-form-item {
    margin-bottom: 15px;
  }
  padding: 15px 15px;
  .card-box {
    width: 100%;
    border: 1px solid #e8e8e8;
    -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: #fff;
    .title-item {
      padding: 15px 20px;
      border-bottom: 1px solid #e8e8e8;
      position: relative;
      .title {
        border-left: 2px solid #1CA4FB;
        padding-left: 10px;
      }
      .title-txt {
        font-size: 16px;
        margin-right: 13px;
      }
      .title-tips {
        position: absolute;
        top: 15px;
        right: 20px;
        font-size: 12px;
      }
    }
  }
}
.common-cont {
  position: relative;
  line-height: 30px;
  padding: 20px 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.iframe-main {
  width: 100%;
  height: calc(100% - 10px);
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.iframe-box {
  width: 100%;
  height: 100%;
}
.iframe-cont {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.noBackBtn.ant-btn::before {
  background: transparent;
}
.com-upload {
  .ant-upload.ant-upload-select-picture-card {
    margin-bottom: 0;
  }
}
.com-col {
  .ant-row {
    margin-bottom: 0;
  }
}
.com-vertical-align {
  vertical-align: middle;
}
.com-row {
  .ant-form-item-control {
    line-height: 0;
  }
}
.com-upload-88 {
  .ant-upload.ant-upload-select-picture-card {
    width: 88px;
    height: 88px;
    margin-right: 15px;
    margin-bottom: 10px;
  }
}
.cell-class-red {
  background-color: red;
}
.cell-class-green {
  background-color: green;
}
.cell-class-yellow {
  background-color: yellow;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-26 {
  font-size: 26px;
}
.print-dialog {
  width: 440px;
  padding: 0 10px;
  box-sizing: border-box;
  color: #333;
  font-size: 12px;
  .bold {
    font-weight: 700;
  }
  .pd5 {
    padding: 5px 5px;
  }
  .print-table-cont {
    padding-top: 10px;
  }
  .print-table {
    width: 100%;
    border: 2px solid #333;
    border-collapse:collapse;
    box-sizing: border-box;
    margin-bottom: 15px;
    .print-table-left {
      width: 340px;
    }
    thead {
      tr {
        border-bottom: 2px solid #333;
      }
    }
    th {
      font-weight: 500;
      vertical-align: middle;
    }
    p {
      margin-bottom: 0;
    }
    td {
      vertical-align: middle;
    }
    tbody {
      tr {
        height: 118px;
      }
    }
    .table-r {
      padding-left: 10px;
    }
    .table-td-1 {
      border-bottom: 2px solid #333;
      border-right: 2px solid #333;
    }
    .table-td-2 {
      border-bottom: 2px solid #333;
      .line {
        border-bottom: 1px solid #333;
      }
      p {
        padding: 5px 0;
      }
    }
    .table-td-logo {
      border-right: 2px solid #333;
    }
  }
}

