.ant-table-thead > tr > th {
  text-align: center;
  vertical-align: middle;
}
.ant-table-tbody > tr > td {
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  vertical-align: middle;
}
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
  vertical-align: middle;
}
.side-menu .ant-menu-vertical .ant-menu-item,
.side-menu .ant-menu-vertical-left .ant-menu-item,
.side-menu .ant-menu-vertical-right .ant-menu-item,
.side-menu .ant-menu-inline .ant-menu-item,
.side-menu .ant-menu-vertical .ant-menu-submenu-title,
.side-menu .ant-menu-vertical-left .ant-menu-submenu-title,
.side-menu .ant-menu-vertical-right .ant-menu-submenu-title,
.side-menu .ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}
.side-menu .ant-menu-vertical .ant-menu-item:not(:last-child),
.side-menu .ant-menu-vertical-left .ant-menu-item:not(:last-child),
.side-menu .ant-menu-vertical-right .ant-menu-item:not(:last-child),
.side-menu .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  border: 0;
  vertical-align: baseline;
}
html,
body {
  font: 12px/1.42 "Microsoft Yahei", "Helvetica Neue", Arial, Helvetica, sans-serif, Lato;
}
a {
  cursor: pointer;
  color: inherit;
}
img {
  border: none;
  vertical-align: middle;
}
i {
  font-style: normal;
}
p {
  word-wrap: break-word;
  word-break: break-all;
}
ol,
ul,
li {
  list-style: none;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.clearfix:before {
  content: '';
  line-height: 0;
  display: table;
}
.clearfix:after {
  content: '';
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  overflow: hidden;
}
.clearfix {
  *zoom: 1;
}
.text-c {
  text-align: center;
}
.text-l {
  text-align: left;
}
.text-r {
  text-align: right;
}
.break-word {
  word-wrap: break-word;
  word-break: break-word;
}
.white-nowrap {
  white-space: nowrap;
}
.ant-spin-container > .ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}
.handle-btn {
  margin: 3px;
}
.ant-table-wrapper.middle-table td {
  vertical-align: middle;
}
.manager-table-warp {
  margin: 0 10px;
}
.manager-table-warp .form-manager {
  border-bottom: 0;
  font-size: 13px;
  color: #333;
  padding: 8px 15px;
}
.manager-table-warp .form-table {
  font-size: 13px;
}
.search-form-wrap {
  padding: 10px 20px;
}
.red-light {
  color: #E51C23;
}
.align-bottom-thead th {
  vertical-align: bottom;
}
.ant-table-fixed {
  border-collapse: collapse !important;
}
.headerRow table {
  border-collapse: collapse !important;
}
.headerRow th {
  vertical-align: bottom;
  border: 1px solid black;
  border-right: 1px solid black !important;
  border-bottom: 1px solid black !important;
  border-collapse: collapse !important;
  color: black !important;
  text-align: center;
  font-size: 12px !important;
}
.handle-link {
  color: #1890ff;
  cursor: pointer;
}
.handle-link:hover {
  color: #40a9ff;
}
.left-paginTable .ant-table-pagination.ant-pagination {
  float: left;
}
.ant-btn + .ant-btn {
  margin-left: 15px;
}
.ant-btn-link + .ant-btn-link {
  margin-left: 0;
}
.tooltip-color .ant-tooltip-inner {
  background-color: #1890ff;
}
.tooltip-color .ant-tooltip-arrow::before {
  background-color: #1890ff;
}
.tooltip-width .ant-tooltip-content {
  width: 430px;
}
.orangeTip-color .ant-tooltip-inner {
  background-color: #F08727;
}
.orangeTip-color .ant-tooltip-arrow::before {
  background-color: #F08727;
}
.com-flex {
  width: 100%;
  display: flex;
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.common-box {
  padding: 15px 15px;
}
.common-box .ant-form-item {
  margin-bottom: 15px;
}
.common-box .card-box {
  width: 100%;
  border: 1px solid #e8e8e8;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
}
.common-box .card-box .title-item {
  padding: 15px 20px;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
}
.common-box .card-box .title-item .title {
  border-left: 2px solid #1CA4FB;
  padding-left: 10px;
}
.common-box .card-box .title-item .title-txt {
  font-size: 16px;
  margin-right: 13px;
}
.common-box .card-box .title-item .title-tips {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 12px;
}
.common-cont {
  position: relative;
  line-height: 30px;
  padding: 20px 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.iframe-main {
  width: 100%;
  height: calc(100% - 10px);
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.iframe-box {
  width: 100%;
  height: 100%;
}
.iframe-cont {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.noBackBtn.ant-btn::before {
  background: transparent;
}
.com-upload .ant-upload.ant-upload-select-picture-card {
  margin-bottom: 0;
}
.com-col .ant-row {
  margin-bottom: 0;
}
.com-vertical-align {
  vertical-align: middle;
}
.com-row .ant-form-item-control {
  line-height: 0;
}
.com-upload-88 .ant-upload.ant-upload-select-picture-card {
  width: 88px;
  height: 88px;
  margin-right: 15px;
  margin-bottom: 10px;
}
.cell-class-red {
  background-color: red;
}
.cell-class-green {
  background-color: green;
}
.cell-class-yellow {
  background-color: yellow;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-26 {
  font-size: 26px;
}
.print-dialog {
  width: 440px;
  padding: 0 10px;
  box-sizing: border-box;
  color: #333;
  font-size: 12px;
}
.print-dialog .bold {
  font-weight: 700;
}
.print-dialog .pd5 {
  padding: 5px 5px;
}
.print-dialog .print-table-cont {
  padding-top: 10px;
}
.print-dialog .print-table {
  width: 100%;
  border: 2px solid #333;
  border-collapse: collapse;
  box-sizing: border-box;
  margin-bottom: 15px;
}
.print-dialog .print-table .print-table-left {
  width: 340px;
}
.print-dialog .print-table thead tr {
  border-bottom: 2px solid #333;
}
.print-dialog .print-table th {
  font-weight: 500;
  vertical-align: middle;
}
.print-dialog .print-table p {
  margin-bottom: 0;
}
.print-dialog .print-table td {
  vertical-align: middle;
}
.print-dialog .print-table tbody tr {
  height: 118px;
}
.print-dialog .print-table .table-r {
  padding-left: 10px;
}
.print-dialog .print-table .table-td-1 {
  border-bottom: 2px solid #333;
  border-right: 2px solid #333;
}
.print-dialog .print-table .table-td-2 {
  border-bottom: 2px solid #333;
}
.print-dialog .print-table .table-td-2 .line {
  border-bottom: 1px solid #333;
}
.print-dialog .print-table .table-td-2 p {
  padding: 5px 0;
}
.print-dialog .print-table .table-td-logo {
  border-right: 2px solid #333;
}
.fixed-frame {
  position: absolute;
  top: 56px;
  bottom: 0;
  left: 200px;
  right: 0;
  overflow: auto;
}
