.login-box {
  width: 100%;
  min-width: 1200px;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: #f3f3f3;
}
.htmlheader {
  width: 100%;
  min-width: 1200px;
  background-color: #fff;
  margin: 0 auto;
}
.header-wrap {
  width: 1200px;
  padding: 20px 0;
  margin: 0 auto;
  cursor: pointer;
}
.logo-img {
  width: 202px;
  height: 50px;
}
.welcome {
  margin-left: 15px;
  font-size: 24px;
  color: #E7152E;
}
.login-cont {
  width: 100%;
}
.login-flex {
  width: 100%;
  display: flex;
  justify-content: center; /* 水平居中 */  
  align-items: center; /* 垂直居中 */  
  height: calc(100vh - 200px);
  margin: 0 auto;
}
.login-item {
  width: 1200px;
  display: flex;
  margin: 0 auto;
}
.login-banner {
  width: 743px;
  height: 410px;
}

.login-right {
  width: 457px;
  height: 410px;
  background-color: #fff;
  padding: 25px 25px;
  box-sizing: border-box;
}
.login-title {
  font-size: 20px;
  color: #4A4A4A;
}
.prompt {
  width: 100%;
  height: 26px;
  font-size: 12px;
  margin: 32px auto;
  position: relative;
}
.pro1 {
  width: 100%;
  background: #fdf3c2;
  border: 1px solid #ffdfa1;
  font-size: 12px;
}
.error-msg {
  display: inline-block;
  vertical-align: middle;
}
.login-Input {
  height: 38px;
}
.htmlfooter {
  width: 100%;
  min-width: 1200px;
  padding: 30px 0;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
}
.htmlfooter div {
  font-size: 12px;
  color: #878787;
  text-align: center;
}
.htmlfooter div a {
  font-size: 14px;
  color: #878787;
  margin: 0 10px 0 10px;
}