.ant-table-thead > tr > th {
  text-align: center;
  vertical-align: middle;
}
.ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-all;
    text-align: center;
    vertical-align: middle;
}

.ant-table-tbody > tr > td.ant-table-selection-column{
  text-align: center;
  vertical-align: middle;
}

.side-menu {
    .ant-menu-vertical .ant-menu-item,
    .ant-menu-vertical-left .ant-menu-item,
    .ant-menu-vertical-right .ant-menu-item,
    .ant-menu-inline .ant-menu-item,
    .ant-menu-vertical .ant-menu-submenu-title,
    .ant-menu-vertical-left .ant-menu-submenu-title,
    .ant-menu-vertical-right .ant-menu-submenu-title,
    .ant-menu-inline .ant-menu-submenu-title {
        margin-top: 0;
        margin-bottom: 0;
    }
    .ant-menu-vertical .ant-menu-item:not(:last-child),
    .ant-menu-vertical-left .ant-menu-item:not(:last-child),
    .ant-menu-vertical-right .ant-menu-item:not(:last-child),
    .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: 0;
    }
}
