.pageHeader {
  height: 56px;
  line-height: 56px;
  font-size: 24px;
  color: #fff;
  padding:0 30px;
  background-color: #1890ff;
}
.userName {
  font-size: 14px;
  margin: 0 10px;
}
.logout {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
}
.logo-img {
  width: auto;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  margin-top: -5px;
}