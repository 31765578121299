.side-menu {
    width: 200px;
    position: fixed;
    z-index: 999;
    top: 56px;
    bottom: 0;
    left: 0;
    width: 200px;
    overflow: auto;
    box-shadow: 0 4px 4px 2px #ddd;
    background: #fff;
}
.tabs {
  position: fixed;
  z-index: 999;
  top: 56px;
  left: 200px;
  background: rgb(239,238,240);
  width: calc(100% - 200px);
  height:40px;
  
}
.tabs :global(.ant-tabs-bar) {
  margin-bottom: 0;
}
.iframe-cont {
  width: 100%;
  height: 1200px;
}